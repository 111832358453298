<template>
  <div class="flex header-bg header" v-if="showNav">
    <md-icon name="arrow-left" size="lg" v-if="icon" @click="goBack"></md-icon>
    <div :class="icon ? '' : 'flex-center'">{{ title }}</div>
    <div> </div>
  </div>
</template>

<script>
export default {
  name: "TitleHeader",
  props: {
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showNav: 0
    }
  },
  created() {
    this.showNav = parseInt(this.$route.query.show_nav || 0);
  },
  mounted() {
    document.title = this.title
  },
  methods: {
    goBack() {
      this.$router.back();
    }
  }
}
</script>

<style lang="less" scoped>
.header {
  font-size: 0.35rem;
  padding: 0.25rem;
  justify-content: space-between;
  align-items: center;
}
</style>
